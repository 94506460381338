import React from 'react'
import { DiscussionEmbed } from 'disqus-react'

const Comments = ({
  disqusID,
  title,
  slug,
}: {
  disqusID: string
  title: string
  slug: string
}) => {
  return (
    <DiscussionEmbed
      shortname="krekotun"
      config={{
        title,
        url: `https://crecotun.com${slug}`,
        identifier: disqusID,
      }}
    />
  )
}

export default Comments
