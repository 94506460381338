import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Share from '../components/Share'
import Comments from '../components/Comments'

class BlogPostTemplate extends React.Component<any> {
  render() {
    const post = this.props.data.markdownRemark

    const { title, date, disqus_id, path } = post.frontmatter

    return (
      <Layout>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <article className="article">
          <header className="article--header">
            <h1 className="article--title">{title}</h1>
            <time className="article--date" dateTime={date}>
              {date}
            </time>
          </header>

          <div className="article--body">
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
          <footer className="article--footer">
            <div className="article--share">
              <Share title={title} />
            </div>
          </footer>

          <div className="article--comments">
            <Comments disqusID={disqus_id} title={title} slug={path} />
          </div>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        path
      }
    }
  }
`
