import React, { Component } from "react"
import likely from "ilyabirman-likely"
import "ilyabirman-likely/release/likely.css"

class Share extends Component<any> {
  componentDidMount() {
    likely.initiate()
  }

  render() {
    const { title } = this.props

    return (
      <div className="likely" data-title={title}>
        <div className="twitter">Твитнуть</div>
        <div className="facebook">Поделиться</div>
        <div className="vkontakte">Поделиться</div>
        <div className="telegram">Отправить</div>
      </div>
    )
  }
}

export default Share
